import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { Spin } from "antd";
const BASE_URL = "http://admin.probacus.com/";

export default class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      image: "",
      description: "",
      blog_id: "",
      date: "",
      loading: false,
      posts: [],
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("data");
    if (!id) {
      window.location.assign("/blog-posts");
      return;
    } else {
      this.setState(
        {
          blog_id: id,
        },
        () => this.getBLogDetails()
      );
    }
  }
  getBLogDetails = () => {
    this.setState({ loading: true });
    let url = "https://admin.probacus.com/api/blog/get";
    let headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(url, { headers: headers })
      .then((response) => {
        console.log("blog list", response);
        for (let item of response.data.data) {
          if (item.blog_id == this.state.blog_id) {
            this.setState({
              title: item.title,
              image: item.image_url,
              description: item.description,
              date: moment(item.date).format("DD MMM, YYYY"),
            });
          }
        }
        this.setState({
          posts: response.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };
  render() {
    return (
      <div class="blog-details-page">
        <Header />
        <section class="blog-details">
          {this.state.title && (
            <div className="row">
              <div className="col-lg-11 m-auto">
                <div className="main-heading text-center">
                  <strong>
                    <h1>{this.state.title}</h1>
                  </strong>
                  <p>Published on {this.state.date}</p>
                </div>
              </div>
            </div>
          )}
          {this.state.image && (
            <div class="main-banner-image">
              <img src={this.state.image} alt="" />
            </div>
          )}
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div class="blog-description">
                  {parse(this.state.description)}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="blog-posts">
          <Spin spinning={this.state.loading}>
            <div class="container">
              <div class="row">
                <h2>Latest Posts</h2>
                <hr />
                {this.state.posts &&
                  this.state.posts.map((item, index) => {
                    let desc =
                      item.short_desc.length > 100
                        ? item.short_desc.substring(0, 100) + "..."
                        : item.short_desc + "...";
                    let url = BASE_URL + item.image;
                    return (
                      <div class="col-lg-4" key={index}>
                        <div class="single-post">
                          <a href={`/blog-details?data=${item.blog_id}`}>
                            <div>
                              <div className="img-div">
                                <img src={url} alt="blog img" />
                              </div>
                              <div class="content">
                                <h4>{item.title}</h4>
                                <p>
                                  {desc}
                                  <a
                                    href={`/blog-details?data=${item.blog_id}`}
                                    style={{ color: "#087bc1" }}
                                  >
                                    Read More.
                                  </a>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Spin>
        </section>
        <Footer />
      </div>
    );
  }
}
