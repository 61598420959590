import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CountUp from "react-countup";
import { message } from "antd";
import ContactForm from "./components/ContactForm";
import { Helmet } from "react-helmet";
import $ from "jquery";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      name: "",
      email: "",
      mobile: "",
      subject: "",
      messageText: "",
      activeBlock: "",
      scrollTopPosition: 0,
      isLoadedCount: false,
      globalBackground: "./assets/images/probacus-global.png",
      fadeInClass: "",
    };
  }
  handleScroll = (event) => {
    console.log("event", event);
    const position = window.pageYOffset;
    this.setState({
      scrollTopPosition: position,
    });
    console.log("position", position);
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // console.log("page scroll", window.pageXOffset);
  }
  openCity = (cityName) => {
    if (cityName == "London") {
      this.setState({
        activeTab: 1,
      });
    }
    if (cityName == "Paris") {
      this.setState({
        activeTab: 2,
      });
    }
  };
  openEmail = () => {
    window.location.assign("mailto:info@probacus.com");
  };
  submit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if the entered email matches the pattern
    const isValid = emailRegex.test(this.state.email);

    const mobileRegex = /^[0-9]{10}$/;
    const isValidMobile = mobileRegex.test(this.state.mobile);

    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    } else if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    } else if (isValid == false) {
      message.warning("Invalid Email address format.");
      return;
    } else if (!this.state.mobile) {
      message.warning("Mobile number is required.");
      return;
    } else if (isValidMobile == false) {
      message.warning("Invalid Mobile number.");
      return;
    } else if (!this.state.subject) {
      message.warning("Subject is required.");
      return;
    }
    let params = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      message: this.state.messageText,
      subject: this.state.subject,
    };
    console.log("params", params);
  };
  setData = (params) => {
    this.setState({
      activeBlock: params,
    });
  };
  removeData = () => {
    this.setState({
      activeBlock: "",
    });
  };
  onHoverInRegion = (param) => {
    if (param == "india") {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground: "./assets/images/regions/Abacus_Map_India.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    } else if (param == "middle-east") {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground:
            "./assets/images/regions/Abacus_Map_Middle_East.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    } else if (param == "east-asia") {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground: "./assets/images/regions/Abacus_Map_East Asia.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    } else if (param == "europe") {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground: "./assets/images/regions/Abacus_Map_Europe.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    } else if (param == "americas") {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground: "./assets/images/regions/Abacus_Map_Americas.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    } else {
      $("#global-footprint-section")
        .removeClass("fade-in3")
        .addClass("fade-out");
      this.setState(
        {
          globalBackground: "./assets/images/probacus-global.png",
        },
        () => {
          $("#global-footprint-section")
            .addClass("fade-in3")
            .removeClass("fade-out");
        }
      );
    }
  };
  onHoverOutRegion = () => {
    $("#global-footprint-section").removeClass("fade-in3").addClass("fade-out");
    this.setState(
      {
        globalBackground: "./assets/images/probacus-global.png",
      },
      () => {
        $("#global-footprint-section")
          .addClass("fade-in3")
          .removeClass("fade-out");
      }
    );
  };
  render() {
    return (
      <div className="homepage">
        <Helmet>
          <title>Home</title>
          <meta
            name="description"
            content="Welcome to Probacus Engineering Solutions – Your trusted partner for cutting-edge automation and engineering solutions. Explore innovative technologies to elevate your industrial processes."
          />
          <meta
            name="keywords"
            content="Automation Solutions, Engineering Services, Industrial Innovation, Process Optimization"
          />
        </Helmet>
        <Header />
        <section class="hero-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="tilt-area">
                  <div class="background-overlay"></div>
                  {/* <img src="./assets/images/tilt-image.jpg" /> */}
                </div>
                <div class="skew-block"></div>
                <div className="content">
                  <div class="mini-logo">
                    <img src="./assets/images/logo-min.png" />
                    {/* <div className="web-title" id="text-blob">
                      Probacus
                    </div> */}
                    <div id="text-blob">Probacus</div>
                  </div>
                  <div class="row para">
                    <div class="col-lg-5 m-auto">
                      <h5>We</h5>
                      <h4>Deliver</h4>
                      <p className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        &nbsp;&nbsp;&nbsp;&nbsp; wide range of process
                        engineering solutions ranging from, automation, MES, PI
                        system implementation, Advisory and consulting support,
                        process design and optimization using leading software
                        technology.
                      </p>
                      <p className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        Wide range of process engineering solutions ranging
                        from, automation, MES, PI system implementation,
                        Advisory and consulting support, process design and
                        optimization using leading software technology.
                      </p>
                    </div>
                  </div>
                  <div class="container r-container">
                    <div class="row custom-row">
                      <div class="col-lg-6">
                        <div
                          class="hover-accordion accordion d-none d-sm-none d-md-none d-lg-block d-xl-block"
                          id="accordionExample"
                        >
                          <div class="accordion-item">
                            <h2
                              class="accordion-header"
                              id="headingOne"
                              onMouseOver={() => this.setData(1)}
                              onMouseOut={() => this.removeData()}
                            >
                              <div class="wpr-acc-icon-box">
                                <span class="wpr-title-icon">
                                  {/* <img
                                    style={{ position: "relative", top: 2 }}
                                    src="./assets/images/accordion/1.png"
                                  /> */}
                                  <span class="material-icon material-symbols-outlined">
                                    autorenew
                                  </span>
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "31.25px solid transparent",
                                    borderBottom: "31.25px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button class="accordion-button collapsed">
                                System Automation /Integration / Analytics
                              </button>
                            </h2>
                          </div>
                          <div class="accordion-item">
                            <h2
                              class="accordion-header"
                              onMouseOver={() => this.setData(2)}
                              onMouseOut={() => this.removeData()}
                            >
                              <div class="wpr-acc-icon-box">
                                <span class="wpr-title-icon">
                                  {/* <img
                                    style={{ position: "relative", top: 13 }}
                                    src="./assets/images/accordion/2.png"
                                  /> */}
                                  <span class="material-icon material-symbols-outlined">
                                    devices
                                  </span>
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "34px solid transparent",
                                    borderBottom: "38px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button class="accordion-button collapsed">
                                Custom Software development for System
                                Integration
                              </button>
                            </h2>
                          </div>
                          <div class="accordion-item">
                            <h2
                              class="accordion-header"
                              id="headingThree"
                              onMouseOver={() => this.setData(3)}
                              onMouseOut={() => this.removeData()}
                            >
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 2,
                                    top: 0,
                                  }}
                                >
                                  {/* <img
                                    style={{ position: "relative", top: 13 }}
                                    src="./assets/images/accordion/3.png"
                                  /> */}
                                  <svg
                                    aria-hidden="true"
                                    class="e-font-icon-svg e-fas-project-diagram"
                                    viewBox="0 0 640 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#fff"
                                  >
                                    <path d="M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z"></path>
                                  </svg>
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "34px solid transparent",
                                    borderBottom: "37px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button class="accordion-button collapsed">
                                Process Simulation, Optimization and Operations
                                support
                              </button>
                            </h2>
                          </div>
                          <div class="accordion-item">
                            <h2
                              class="accordion-header"
                              id="headingFour"
                              onMouseOver={() => this.setData(4)}
                              onMouseOut={() => this.removeData()}
                            >
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 1,
                                    top: 0,
                                  }}
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="e-font-icon-svg e-fas-sitemap"
                                    viewBox="0 0 640 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#fff"
                                  >
                                    <path d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"></path>
                                  </svg>
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "31px solid transparent",
                                    borderBottom: "36px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button class="accordion-button collapsed">
                                Process design and intensification
                              </button>
                            </h2>
                          </div>
                          <div class="accordion-item">
                            <h2
                              class="accordion-header"
                              id="headingFive"
                              onMouseOver={() => this.setData(5)}
                              onMouseOut={() => this.removeData()}
                            >
                              <div class="wpr-acc-icon-box">
                                <span class="wpr-title-icon">
                                  {/* <img
                                    style={{ position: "relative", top: 7 }}
                                    src="./assets/images/accordion/5.png"
                                  /> */}
                                  <span class="material-icon material-symbols-outlined">
                                    support
                                  </span>
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "29px solid transparent",
                                    borderBottom: "32px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button class="accordion-button collapsed">
                                Training and Process Safety
                              </button>
                            </h2>
                          </div>
                        </div>
                        <div
                          class="accordion d-block d-sm-block d-md-block d-lg-none d-xl-none"
                          id="accordionExample"
                        >
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <div class="wpr-acc-icon-box">
                                <span class="wpr-title-icon">
                                  <img
                                    style={{ position: "relative", top: 8 }}
                                    src="./assets/images/accordion/1.png"
                                  />
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "31.25px solid transparent",
                                    borderBottom: "39.25px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                System Automation /Integration / Analytics
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                Modern process plants operate in a highly
                                integrated and automated environment. Data is
                                captured in various control systems needs to
                                stored, retrieved, archived faithfully and
                                optimally in historians. There is a higher
                                demand on management to analyze data and improve
                                operations.Data available within the controlled
                                system at site needs to be shared in real-time
                                environment with the corporate office. We
                                provide data integration, analytics and
                                reporting services. We specialize in
                                implementing AVEVA PI System (formerly OSIsoft
                                PI System) software which is a leading software
                                solution.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 17,
                                    top: 12,
                                  }}
                                >
                                  <img
                                    style={{ position: "relative", top: 13 }}
                                    src="./assets/images/accordion/2.png"
                                  />
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "41.25px solid transparent",
                                    borderBottom: "44.25px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                Custom Software development for System
                                Integration
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                Our unparallel custom software development to
                                integrate process engineering solutions enables
                                customers with handy tools and software to drive
                                their simulation projects better. We specialize
                                in providing web services and website
                                integration with AVEVA APO (formerly ROMeo) to
                                submit data, drive simulation, fetch data back
                                into website and display with custom reporting.
                                Examples of such custom software tools include
                                Python Interface, Excel addin and webservices
                                which are being used by AVEVA’s premium
                                customers worldwide.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 17,
                                    top: 12,
                                  }}
                                >
                                  <img
                                    style={{ position: "relative", top: 13 }}
                                    src="./assets/images/accordion/3.png"
                                  />
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "41.25px solid transparent",
                                    borderBottom: "44.25px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                Process Simulation, Optimization and Operations
                                support
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                We have expertise in implementing Real Time
                                Optimization (RTO) using AVEVA APO (Aveva
                                Process Optimizer) software. We develop and
                                integrate reporting system with database, PI
                                historian and dashboards based on requirements.
                                We also have expertise in Dynamic Simulation and
                                Steady State Simulation
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 20,
                                    top: 7,
                                  }}
                                >
                                  <img
                                    style={{ position: "relative", top: 10 }}
                                    src="./assets/images/accordion/4.png"
                                  />
                                </span>
                                <div
                                  class="wpr-acc-icon-box-after"
                                  style={{
                                    borderTop: "35.25px solid transparent",
                                    borderBottom: "36.25px solid transparent",
                                  }}
                                ></div>
                              </div>
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                              >
                                Process design and intensification
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                We provide Process design and engineering
                                support to domestic industry by way of
                                development of Basic Engineering Package (BEP)
                                and Detailed Engineering Package (DEP) We
                                delivered critical and proprietary equipment
                                design (e.g. reactor, distillation column and
                                heat exchanger) to our esteemed clients in
                                pharmaceutical industry and specialty chemicals
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                              <div class="wpr-acc-icon-box">
                                <span
                                  class="wpr-title-icon"
                                  style={{
                                    left: 19,
                                    top: 7,
                                  }}
                                >
                                  <img
                                    style={{ position: "relative", top: 7 }}
                                    src="./assets/images/accordion/5.png"
                                  />
                                </span>
                                <div class="wpr-acc-icon-box-after"></div>
                              </div>
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                Training and Process Safety
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingFive"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                We have delivered various custom Industrial
                                training programs ranging from Process Safety,
                                Simulation, Engineering and design,
                                Post-implementation training
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="our-service-block">
                          <div class="background-overlay"></div>
                          <div
                            class={
                              this.state.activeBlock ? "content" : "content"
                            }
                          >
                            <div class="subtitle">Our Services</div>
                            <h1>
                              We Excel In Delivering The Following Specialized
                              Services:
                            </h1>
                            {this.state.activeBlock == "1" && (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,
                                  lineHeight: "25px",
                                  fontWeight: "400",
                                  padding: 0,
                                }}
                                class="fade-in2"
                              >
                                Modern process plants operate in a highly
                                integrated and automated environment. Data is
                                captured in various control systems needs to
                                stored, retrieved, archived faithfully and
                                optimally in historians. There is a higher
                                demand on management to analyze data and improve
                                operations.Data available within the controlled
                                system at site needs to be shared in real-time
                                environment with the corporate office. We
                                provide data integration, analytics and
                                reporting services. We specialize in
                                implementing AVEVA PI System (formerly OSIsoft
                                PI System) software which is a leading software
                                solution.
                              </p>
                            )}
                            {this.state.activeBlock == "2" && (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,
                                  lineHeight: "25px",
                                  fontWeight: "400",
                                  padding: 0,
                                }}
                                class="fade-in2"
                              >
                                Our unparallel custom software development to
                                integrate process engineering solutions enables
                                customers with handy tools and software to drive
                                their simulation projects better. We specialize
                                in providing web services and website
                                integration with AVEVA APO (formerly ROMeo) to
                                submit data, drive simulation, fetch data back
                                into website and display with custom reporting.
                                Examples of such custom software tools include
                                Python Interface, Excel addin and webservices
                                which are being used by AVEVA’s premium
                                customers worldwide.
                              </p>
                            )}
                            {this.state.activeBlock == "3" && (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,
                                  lineHeight: "25px",
                                  fontWeight: "400",
                                  padding: 0,
                                }}
                                class="fade-in2"
                              >
                                We have expertise in implementing Real Time
                                Optimization (RTO) using AVEVA APO (Aveva
                                Process Optimizer) software. We develop and
                                integrate reporting system with database, PI
                                historian and dashboards based on requirements.
                                We also have expertise in Dynamic Simulation and
                                Steady State Simulation
                              </p>
                            )}
                            {this.state.activeBlock == "4" && (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,
                                  lineHeight: "25px",
                                  fontWeight: "400",
                                  padding: 0,
                                }}
                                class="fade-in2"
                              >
                                We provide Process design and engineering
                                support to domestic industry by way of
                                development of Basic Engineering Package (BEP)
                                and Detailed Engineering Package (DEP) We
                                delivered critical and proprietary equipment
                                design (e.g. reactor, distillation column and
                                heat exchanger) to our esteemed clients in
                                pharmaceutical industry and specialty chemicals
                              </p>
                            )}
                            {this.state.activeBlock == "5" && (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,
                                  lineHeight: "25px",
                                  fontWeight: "400",
                                  padding: 0,
                                }}
                                class="fade-in2"
                              >
                                We have delivered various custom Industrial
                                training programs ranging from Process Safety,
                                Simulation, Engineering and design,
                                Post-implementation training
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Start abount section  */}
        <section class="about-section">
          <div class="background-overlay"></div>
          <div class="content">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <h4 class="subheading">Statistics</h4>
                  <h1 class="main-heading">About Us</h1>
                </div>
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="count-section">
                        <h1>
                          {Number(this.state.scrollTopPosition) > 930 ? (
                            <>
                              {this.state.isLoadedCount == true ? (
                                <span>13 +</span>
                              ) : (
                                <>
                                  <CountUp
                                    end="13"
                                    onEnd={() =>
                                      this.setState({ isLoadedCount: true })
                                    }
                                  />{" "}
                                  +
                                </>
                              )}
                            </>
                          ) : (
                            <span>13 +</span>
                          )}
                        </h1>
                        <p>Years</p>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="count-section">
                        <h1>
                          {this.state.scrollTopPosition > 930 ? (
                            <>
                              {this.state.isLoadedCount == true ? (
                                <span>25 +</span>
                              ) : (
                                <>
                                  <CountUp
                                    end="25"
                                    onEnd={() =>
                                      this.setState({ isLoadedCount: true })
                                    }
                                  />{" "}
                                  +
                                </>
                              )}
                            </>
                          ) : (
                            <span>25 +</span>
                          )}
                        </h1>
                        <p>Projects</p>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="count-section">
                        <h1>
                          {this.state.scrollTopPosition > 930 ? (
                            <>
                              {this.state.isLoadedCount == true ? (
                                <span>10 +</span>
                              ) : (
                                <>
                                  <CountUp
                                    end="10"
                                    onEnd={() =>
                                      this.setState({ isLoadedCount: true })
                                    }
                                  />{" "}
                                  +
                                </>
                              )}
                            </>
                          ) : (
                            <span>10 +</span>
                          )}
                        </h1>
                        <p>Clients</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End abount section */}
        <section class="business-focus-section">
          <h1 class="text-center main-heading">Business Focus</h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-12 m-auto">
                <div class="tab">
                  <button
                    class={
                      this.state.activeTab == "1"
                        ? `tablinks active`
                        : `tablinks `
                    }
                    onClick={() => this.openCity("London")}
                  >
                    PI software implementation
                  </button>
                  <button
                    class={
                      this.state.activeTab == "2"
                        ? `tablinks active`
                        : `tablinks `
                    }
                    onClick={() => this.openCity("Paris")}
                  >
                    APO implantation for Real Time Optimization (RTO)
                  </button>
                </div>
                <div
                  id="London"
                  class="tabcontent"
                  style={{
                    display: this.state.activeTab == "1" ? "block" : "none",
                  }}
                >
                  <div class="container">
                    <div class="row px-0">
                      <div class="col-lg-6 px-0">
                        <div class="content">
                          <img
                            src="./assets/images/business-focus/1.png"
                            alt=""
                          />
                          <h2 class="text-center">
                            PI Software Implementation
                          </h2>
                          <p>
                            We provide PI system implementation and integration
                            services to
                            <br /> Refinery, Gas Plants, Petrochemicals and LNG
                            terminals. We have
                            <br /> developed our unique skillset to reproduce
                            high fidelity
                            <br /> conversion of complex DCS graphics and
                            dashboards from native
                            <br /> DCS graphics into PIVision graphical
                            framework. This is
                            <br />
                            demonstrated through our successfully completed
                            projects like <br />
                            DLTPL and TempaRossa PI implementations.  We
                            develop AF
                            <br /> Framework and custom user utilities within
                            the PIVision
                            <br /> framework for ease of use
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-6 px-0">
                        <div class="image">
                          <div class="background-overlay"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="Paris"
                  class="tabcontent"
                  style={{
                    display: this.state.activeTab == "2" ? "block" : "none",
                  }}
                >
                  <div class="container">
                    <div class="row px-0">
                      <div class="col-lg-6 px-0">
                        <div class="image2">
                          <div class="background-overlay2"></div>
                        </div>
                      </div>
                      <div class="col-lg-6 px-0">
                        <div class="content">
                          <img
                            src="./assets/images/business-focus/1.png"
                            alt=""
                          />
                          <h2 class="text-center">
                            APO Implantation For Real Time <br />
                            Optimization (RTO)
                          </h2>
                          <p>
                            Probacus specializes in the implementation of
                            Advanced Process
                            <br /> Optimization (APO) for Real-Time Optimization
                            (RTO). Our expert
                            <br /> team deploys sophisticated algorithms and
                            cutting-edge control
                            <br />
                            strategies to enhance industrial processes in
                            real-time. By
                            <br /> adjusting key parameters on the fly, we
                            ensure optimal
                            <br /> performance, improved efficiency, and
                            resource utilization. With
                            <br /> Probacus at the helm, your operations benefit
                            from advanced <br />
                            solutions that maximize productivity and drive
                            sustained success <br />
                            in a dynamically changing environment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="industry-section">
          <div class="background-overlay"></div>
          <div class="content">
            <h2 class="text-center main-heading">Industry Domain</h2>
            <p class="subtitle text-center">
              We have catered to following process industry and would like to
              focus our business on PI implementation
            </p>
            <div class="container">
              <div class="row">
                <div class="col-lg-3">
                  <div class="sub-block">
                    <div class="circle">
                      <div class="icon">
                        <img src="./assets/images/industry-domain/1.png" />
                      </div>
                    </div>
                    <h4>
                      Refinery and Gas
                      <br />
                      plants
                    </h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="sub-block">
                    <div class="circle">
                      <div class="icon">
                        <img src="./assets/images/industry-domain/2.png" />
                      </div>
                    </div>
                    <h4>Petrochemicals</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="sub-block">
                    <div class="circle">
                      <div class="icon">
                        <img
                          style={{ top: 45 }}
                          src="./assets/images/industry-domain/3.png"
                        />
                      </div>
                    </div>
                    <h4>LNG Terminals</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="sub-block">
                    <div class="circle">
                      <div class="icon">
                        <img src="./assets/images/industry-domain/4.png" />
                      </div>
                    </div>
                    <h4>Thermal Power</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class={`global-footprint-section`}>
          <div
            class="background-overlay"
            style={{
              background: `url("${this.state.globalBackground}")`,
            }}
            id="global-footprint-section"
          >
            <div className="inner"></div>
          </div>
          <div class="content-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 m-auto">
                  <div class="logo-section-wrapper">
                    <div class="logo-section">
                      <img src="./assets/images/logo-min.png" alt="logo" />
                    </div>
                  </div>
                  <h1>
                    Probacus <br />
                    Global Footprints
                  </h1>
                  <p>
                    We are working in following geographical regions and would
                    like to further strengthen PI system integration
                  </p>
                  <div class="country-list">
                    <ul>
                      <li>
                        <div
                          className="inner"
                          onMouseOver={() => this.onHoverInRegion("india")}
                          onMouseOut={() => this.onHoverOutRegion()}
                        >
                          <div class="icon">
                            <i class="bx bxs-flag-alt"></i>
                          </div>
                          <span>India</span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="inner"
                          onMouseOver={() =>
                            this.onHoverInRegion("middle-east")
                          }
                          onMouseOut={() => this.onHoverOutRegion()}
                        >
                          <div class="icon">
                            <i class="bx bxs-flag-alt"></i>
                          </div>
                          <span>Middle East</span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="inner"
                          onMouseOver={() => this.onHoverInRegion("east-asia")}
                          onMouseOut={() => this.onHoverOutRegion()}
                        >
                          <div class="icon">
                            <i class="bx bxs-flag-alt"></i>
                          </div>
                          <span>East Asia</span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="inner"
                          onMouseOver={() => this.onHoverInRegion("europe")}
                          onMouseOut={() => this.onHoverOutRegion()}
                        >
                          <div class="icon">
                            <i class="bx bxs-flag-alt"></i>
                          </div>
                          <span>Europe</span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="inner"
                          onMouseOver={() => this.onHoverInRegion("americas")}
                          onMouseOut={() => this.onHoverOutRegion()}
                        >
                          <div class="icon">
                            <i class="bx bxs-flag-alt"></i>
                          </div>
                          <span>Americas</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="contact-us-section" id="contact-form">
          <div class="background-overlay"></div>
          <div class="main-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="white-block">
                    <h1>
                      <span
                        class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
                        style={{ fontWeight: "bolder" }}
                      >
                        Transform Operations With <br />
                        Probacus!
                      </span>
                      <span
                        class="d-block d-sm-block d-md-none d-lg-none d-xl-none"
                        style={{ fontWeight: "bolder" }}
                      >
                        Transform Operations
                        <br /> With Probacus!
                      </span>{" "}
                      From Eco-Friendly Solutions To Cutting-Edge Technology,
                      We're Your Partner For Success. Consult With Us Today!
                    </h1>
                    <div class="email-wrapper">
                      <button
                        class="email-button"
                        onClick={() => this.openEmail()}
                      >
                        <i
                          class="bx bxs-envelope"
                          style={{ position: "relative", top: 2 }}
                        ></i>{" "}
                        &nbsp; info@probacus.com
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <ContactForm classes="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
